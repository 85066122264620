<template>
  <div class="internal-funds-transfer-list-page fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="internalFundsTransferList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="handleRowClicked($event)"
                             @load:more="getInternalFundsTransfers()"/>

    <button id="printTableInternalFundsTransfer" v-show="false" @click="handlePrintInternalTransfer"></button>
  </div>
</template>

<script>
import axios from 'axios'
import {addComma, getTimeFromServer, hideLoading, showLoading} from "../../../../../assets/js/functions";
import {getInternalFundsTransfers, printInternalFundsTransfer} from "../../../../../http/requests/treasury/internalFund";
import {getBanks} from "../../../../../http/requests/treasury/banks";
import {getCashBoxes} from "../../../../../http/requests/treasury/cashBoxes";
import historyStates from "@/mixins/historyStates";
import setDocumentDescription from "@/mixins/setDocumentDescription";

export default {
  name: 'internalFundsTransfersList',
  metaInfo () {
    return {
      title: this.$t('treasury.internalFundsTransfer.title')
    }
  },
  mixins: [historyStates, setDocumentDescription],
  data () {
    return {
      loadingTimer: 0,
      options: {
        id: 'internalFundsTransferList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'price',
          i18n: 'treasury.internalFundsTransfer.table.header.price',
          width: 'calc(100% / 7)',
          minWidth: 120,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          // footer: {
          //   type: 'auto-sum',
          //   textType: 'price'
          // }
        },
        {
          field: 'description',
          i18n: 'treasury.internalFundsTransfer.table.header.description',
          width: 'calc(100% / 7 * 0.7)',
          minWidth: 70,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'payee',
          i18n: 'treasury.internalFundsTransfer.table.header.payee',
          width: 'calc(100% / 7 * 0.7)',
          minWidth: 100,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [{
            label: 'همه',
            value: 0
          }]
        },
        {
          field: 'payer',
          i18n: 'treasury.internalFundsTransfer.table.header.payer',
          width: 'calc(100% / 7 * 0.7)',
          minWidth: 100,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [{
            label: 'همه',
            value: 0
          }]
        },
        {
          field: 'creator',
          i18n: 'treasury.internalFundsTransfer.table.header.creator',
          width: 'calc(100% / 7 * 0.7)',
          minWidth: 40,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'date',
          i18n: 'treasury.internalFundsTransfer.table.header.date',
          width: 'calc(100% / 7 * 0.4)',
          minWidth: 120,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'date',
          filterRange: true
        },
        {
          field: 'id',
          i18n: 'treasury.internalFundsTransfer.table.header.docNumber',
          width: 'calc(100% / 7 * 0.3)',
          minWidth: 30,
          locked: true,
          align: 'center',
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      data: [],
      filters: {},
      selectedFilters: [],
      sorts: ['order[0]=date,desc'],
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: {name: 'insertTreasuryInternalFundsTransfers'},
              type: 'link',
              icon: 'PLUS',
              iconPack: 'useral',
              color: 'success',
              permission: ['internal_fund.create']
            }
          ],
          leftToolbar: [
            // {
            //   id: 'printTableInternalFundsTransfer',
            //   // i18n: 'draggableDynamicTable.actions.print',
            //   icon: 'PRINT',
            //   iconPack: 'useral'
            // },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })

    this.getBanksFilters()
    this.getCashBoxFilters()
    if (history.state.hasOwnProperty('data') && this.$store.state.helper.prevRoute.name !== null) {
      this.getTableInfoFromHistory()
    } else {
      this.getInternalFundsTransfers()
    }
  },
  methods: {
    getInternalFundsTransfers () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.internalFundsTransferList && this.data.length === 0) this.$refs.internalFundsTransferList.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.internalFundsTransferList && this.data.length > 0) this.$refs.internalFundsTransferList.loadMoreStatus = 'Loading'

          getInternalFundsTransfers(this.page, this.filters, this.sorts).then(response => {
            this.endedList = response.data.data.length === 0
            const documents = response.data.data
            documents.forEach((document) => {
              this.data.push({
                route: {
                  name: 'treasuryInternalFundsTransferDocument',
                  params: {id: document.id}
                },
                id: document.document_id,
                creator: `${document.creator.name}`,
                description: document.description ? document.description : this.generateDocumentDescription({
                  type: 'internal_fund_transfer',
                  reference: document
                }, {hasRoute: false}),
                payee: document.payee.name || '',
                payer: document.payer.name || '',
                price: {
                  type: 'price',
                  value: document.price
                },
                type: document.type,
                date: document.date
              })
            })

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('id')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }
            const price_index = this.columnsLabel.map((e) => {
              return e.field
            }).indexOf('price')
            this.columnsLabel[price_index].footer = {
              value: addComma(response.data.extra.total_price)
            }

            if (this.$refs.internalFundsTransferList) this.$refs.internalFundsTransferList.loadMoreStatus = ''
          }).catch((error) => {
            if (this.$refs.internalFundsTransferList && !axios.isCancel(error)) this.$refs.internalFundsTransferList.loadMoreStatus = 'Danger'
          })
        }
      }, 400)
    },
    setFilters (filters) {
      this.selectedFilters = filters
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'id':
            if (filters[key].search !== '') filters_list.document = `${  filters[key].search},${  filters[key].type.id}`
            break

          case 'creator':
            if (filters[key].search !== '') filters_list.creator = filters[key].search
            break

          case 'description':
            if (filters[key].search !== '') filters_list.description = filters[key].search
            break

          case 'price':
            if (filters[key].search !== '') filters_list.price = `${  filters[key].search},${  filters[key].type.id}`
            break

          case 'payer':
            if (filters[key].search.value.length > 0) filters_list.payer = filters[key].search.value
            break

          case 'payee':
            if (filters[key].search.value.length > 0) filters_list.payee = filters[key].search.value
            break

          case 'destination':
            if (filters[key].search.value > 0) filters_list.account = filters[key].search.value
            break

          case 'date':
            if (filters[key].search.length > 0) filters_list.date = filters[key].search.join('_')
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.$nextTick(() => {
        this.getInternalFundsTransfers()
      })
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

          case 'id':
            sorts_list.push(`order[0]=document_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'creator':
            sorts_list.push(`order[0]=creator,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'description':
            sorts_list.push(`order[0]=description,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'price':
            sorts_list.push(`order[0]=price,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'payer':
            sorts_list.push(`order[0]=payer_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'payee':
            sorts_list.push(`order[0]=payee_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'destination':
            sorts_list.push(`order[0]=account,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'date':
            sorts_list.push(`order[0]=date,${  columns[key] ? 'desc' : 'asc'}`)
            break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getInternalFundsTransfers()
      })
    },
    handlePrintInternalTransfer () {
      showLoading()

      printInternalFundsTransfer(this.filters, this.sorts).then(response => {
        hideLoading()

        const data = response.data

        let time = getTimeFromServer()
        let fileURL = window.URL.createObjectURL(new Blob([data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `internalFundsTransferList-${ time }.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })

        .catch((error) => {
          hideLoading()

          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.internalFundsTransfer.notifications.print.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    },
    getBanksFilters () {
      getBanks().then(response => {
        const banks = response.data.data
        const payeeIndex = this.columnsLabel.map(e => e.field).indexOf('payee')
        const payerIndex = this.columnsLabel.map(e => e.field).indexOf('payer')
        banks.forEach((bank) => {
          if (payeeIndex > -1) {
            this.columnsLabel[payeeIndex].filterTypes.push({
              label: bank.name,
              value: bank.name
            })
          }

          if (payerIndex > -1) {
            this.columnsLabel[payerIndex].filterTypes.push({
              label: bank.name,
              value: bank.name
            })
          }
        })
      })
    },
    getCashBoxFilters () {
      getCashBoxes().then(response => {
        const cashBoxes = response.data.data
        const payeeIndex = this.columnsLabel.map(e => e.field).indexOf('payee')
        const payerIndex = this.columnsLabel.map(e => e.field).indexOf('payer')
        cashBoxes.forEach((cashBox) => {
          if (payeeIndex > -1) {
            this.columnsLabel[payeeIndex].filterTypes.push({
              label: cashBox.name,
              value: cashBox.name
            })
          }

          if (payerIndex > -1) {
            this.columnsLabel[payerIndex].filterTypes.push({
              label: cashBox.name,
              value: cashBox.name
            })
          }
        })
      })
    },
    handleRowClicked () {
      this.setTableInfoToHistory()
    }
  }
}
</script>

<style scoped>

</style>
